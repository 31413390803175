
import React from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { db } from '../../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;

const AddDeveloper = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {

      await addDeveloperToFirestore(values);


      message.success('Developer added successfully');
      window.location.reload('adddeveloper')
    } catch (error) {
      console.error('Error adding developer: ', error);
      message.error('Failed to add developer');
    }
  };

  const addDeveloperToFirestore = async (values) => {

    await addDoc(collection(db, 'addDeveloper'), {
      name: values.name,
      email: values.email,
      phone: values.phone,
      title: values.title,
      experience: values.experience,
      resume: values.resume[0].response,
      timestamp: new Date().toLocaleString(),
    });
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', background: '#f7f7f7', borderRadius: '8px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Developer</h2>
      <Form name="add_developer" layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the Name!' }]}>
          <Input placeholder="Enter developer's name" />
        </Form.Item>

        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the Email!', type: 'email' }]}>
          <Input placeholder="Enter developer's email" />
        </Form.Item>

        <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input the Phone number!' }]}>
          <Input placeholder="Enter developer's phone number" />
        </Form.Item>

        <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the Title!' }]}>
          <Input placeholder="Enter developer's title" />
        </Form.Item>

        <Form.Item name="experience" label="Experience" rules={[{ required: true, message: 'Please input the Experience!' }]}>
          <Input placeholder="Enter developer's experience" />
        </Form.Item>

        <Form.Item
          name="resume"
          label="Resume"
          valuePropName="fileList"
          getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
          rules={[{ required: true, message: 'Please upload the Resume!' }]}
        >
          <Dragger name="resume" action="/upload.do" listType="picture">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>

          <Button type="default" onClick={() => navigate('/admin')} block style={{ marginTop: '10px' }}>
            Go Back
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddDeveloper;
