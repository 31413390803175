

import React, { useState, useEffect } from 'react';
import { Table, Button, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import './HireDeveloper.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const HireDeveloper = () => {
    const navigate = useNavigate();
    const [developers, setDevelopers] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const fetchDevelopers = async () => {
            try {
                const developersCollection = collection(db, 'addDeveloper');
                const developersSnapshot = await getDocs(developersCollection);
                const developersList = developersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDevelopers(developersList);
            } catch (error) {
                console.error('Error fetching developers:', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchDevelopers();
    }, []);

    const handleAddNewClick = () => {
        navigate('/add-developer');  
        
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
        },
        {
          title: 'Resume',
          dataIndex: 'resume',
          key: 'resume',
          render: (resume) => (
            <a href={resume} target="_blank" rel="noopener noreferrer">View Resume</a>
          ),
        },
    ];

    return (
        <Layout style={{ padding: '24px', minHeight: '100vh' }}>
            <Header className="hire-developer-header">
                <Title level={2}>List of Posting Developer</Title>
                <Button type="primary" onClick={handleAddNewClick}>
                    Add New
                </Button>
            </Header>
            <Content style={{ marginTop: '24px' }}>
                <Table columns={columns} dataSource={developers} loading={loading} />
            </Content>
        </Layout>
    );
};

export default HireDeveloper;
