import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import JobList from './JobPosting/JobList';
import AddJob from './JobPosting/AddJob';
import EditJob from './JobPosting/EditJob';

const JobPosting = () =>
{
    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const openAddModal = () =>
    {
        setAddModalVisible(true);
    };

    const closeAddModal = () =>
    {
        setAddModalVisible(false);
        setRefresh(!refresh); // Trigger a refresh
    };

    const handleEdit = (job) =>
    {
        setSelectedJob(job);
    };

    const handleEditSuccess = () =>
    {
        setSelectedJob(null);
        setRefresh(!refresh); // Trigger a refresh
    };

    return (
        <div className="p-4">
            <Button type="primary" onClick={openAddModal}>
                Add Job
            </Button>
            <JobList onEdit={handleEdit} />
            <Modal
                title="Add Job"
                visible={isAddModalVisible}
                onCancel={closeAddModal}
                footer={null}
            >
                <AddJob onSuccess={closeAddModal} />
            </Modal>
            <Modal
                title="Edit Job"
                visible={!!selectedJob}
                onCancel={() => setSelectedJob(null)}
                footer={null}
            >
                <EditJob job={selectedJob} onSuccess={handleEditSuccess} onClose={() => setSelectedJob(null)} />
            </Modal>
        </div>
    )
}

export default JobPosting