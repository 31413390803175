// src/components/ServiceManager.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Input, Select, Modal, notification } from 'antd';
import axios from 'axios';
import { getResourceList } from '../../../api/resourceApi';

const { Option } = Select;

const ServiceManager = () =>
{
    const [services, setServices] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editService, setEditService] = useState(null);

    // Fetch services and resources from backend
    useEffect(() =>
    {
        const loadServices = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/service');
                setServices(response.data);
            } catch (error)
            {
                notification.error({
                    message: 'Error loading services',
                    description: 'There was an error fetching the services.',
                });
            }
        };

        const loadResources = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/api/page');
                setResources(response.data);
            } catch (error)
            {
                notification.error({
                    message: 'Error loading resources',
                    description: 'There was an error fetching the resources.',
                });
            }
        };
        loadServices();
        loadResources();

    }, []);

    // Handle form submission for creating or updating service
    const handleSubmit = async () =>
    {
        if (!serviceName || options.length === 0)
        {
            notification.error({
                message: 'Error',
                description: 'Please provide a service name and at least one option.',
            });
            return;
        }

        setLoading(true);

        try
        {
            let response;
            if (editService)
            {
                // Update the service
                response = await axios.put(`https://api.ridgetechcorp.com/service/${ editService._id }`, {
                    name: serviceName,
                    options,
                });
                notification.success({
                    message: 'Service Updated',
                    description: `Service ${ response.data.name } has been updated successfully.`,
                });
                setServices(services.map((svc) => svc._id === editService._id ? response.data : svc));
            } else
            {
                // Create a new service
                response = await axios.post('https://api.ridgetechcorp.com/service', {
                    name: serviceName,
                    options,
                });
                notification.success({
                    message: 'Service Created',
                    description: `Service ${ response.data.name } has been created successfully.`,
                });
                setServices([...services, response.data]);
            }

            // Reset the form
            setServiceName('');
            setOptions([]);
            setIsModalVisible(false);
            setEditService(null);
        } catch (error)
        {
            notification.error({
                message: 'Error',
                description: 'There was an error submitting the service.',
            });
        } finally
        {
            setLoading(false);
        }
    };

    // Handle delete action
    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/service/${ id }`);
            notification.success({ message: 'Service deleted successfully' });
            setServices(services.filter((svc) => svc._id !== id));
        } catch (error)
        {
            notification.error({ message: 'Error deleting service' });
        }
    };

    // Handle options change (selecting multiple options)
    const handleOptionsChange = (value) =>
    {
        setOptions(value);
    };

    // Handle editing of service
    const handleEdit = (service) =>
    {
        console.log(service)
        const optionId = []
        service.options.map((optionDetails) =>
        {
            optionId.push(optionDetails._id)
        })
        setServiceName(service.name);
        setOptions(optionId);
        setEditService(service);
        setIsModalVisible(true);
    };

    // Columns for Service List Table
    const columns = [
        { title: 'Service Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (optionIds) =>
            {
                return optionIds
                    .map(optionId =>
                    {
                        console.log({ optionId })
                        const resource = resources.find(r => r._id.toString() === optionId?._id.toString());
                        return resource ? resource.name : null; // Return resource name or null if not found
                    })
                    .filter(name => name) // Remove any null values
                    .join(', '); // Join the names with a comma
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        Edit
                    </Button>
                    <Button type="link" onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            {/* Button to open modal for creating service */}
            <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 20 }}>
                Add New Service
            </Button>

            {/* Service List Table */}
            <Table rowKey="_id" dataSource={services} columns={columns} pagination={false} bordered />

            {/* Edit/Add Service Modal */}
            <Modal
                title={editService ? 'Edit Service' : 'Add Service'}
                visible={isModalVisible}
                onCancel={() =>
                {
                    setIsModalVisible(false);
                    setEditService(null); // Reset edit
                    setServiceName('');
                    setOptions([]);
                }}
                onOk={handleSubmit}
                confirmLoading={loading}
            >
                <Form layout="vertical">
                    <Form.Item label="Service Name" required>
                        <Input
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                            placeholder="Enter service name"
                        />
                    </Form.Item>

                    <Form.Item label="Service Options" required>
                        <Select
                            mode="multiple"
                            value={options}
                            onChange={handleOptionsChange}
                            placeholder="Select options"
                        >
                            {resources && resources.map((resource) => (
                                <Option key={resource._id} value={resource._id}>
                                    {resource.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ServiceManager;
