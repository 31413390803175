// api/resourceApi.js
import axios from 'axios';

const API_BASE_URL = 'https://api.ridgetechcorp.com/api/resource-parent';
const IMAGE_API_BASE_URL = 'https://api.ridgetechcorp.com/api';

// Get the list of resources
export const getParentResourceList = async () =>
{
    try
    {
        const response = await axios.get(API_BASE_URL);
        return response.data;
    } catch (error)
    {
        console.error('Error fetching resource list:', error);
        throw error;
    }
};

// Create a new resource
export const createParentResource = async (resourceData) =>
{
    try
    {
        const response = await axios.post(API_BASE_URL, resourceData);
        return response.data;
    } catch (error)
    {
        console.error('Error creating resource:', error);
        throw error;
    }
};

// Update an existing resource
export const updateParentResource = async (id, updatedData) =>
{
    try
    {
        const response = await axios.put(`${ API_BASE_URL }/${ id }`, updatedData);
        return response.data;
    } catch (error)
    {
        console.error('Error updating resource:', error);
        throw error;
    }
};

// Delete a resource
export const deleteParentResource = async (id) =>
{
    try
    {
        const response = await axios.delete(`${ API_BASE_URL }/${ id }`);
        return response.data;
    } catch (error)
    {
        console.error('Error deleting resource:', error);
        throw error;
    }
};



// Upload a resource icon
export const uploadParentResourceIcon = async (file) =>
{
    try
    {
        // const formData = new FormData();
        // formData.append('image', file);

        const response = await axios.post(`${ IMAGE_API_BASE_URL }/upload`, file, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data; // Assuming the response contains the image URL
    } catch (error)
    {
        console.error('Error uploading resource icon:', error);
        throw error;
    }
};

// Delete an uploaded icon
export const deleteParentResourceIcon = async (imageId) =>
{
    try
    {
        const response = await axios.delete(`${ IMAGE_API_BASE_URL }/image/${ imageId }`);
        return response.data;
    } catch (error)
    {
        console.error('Error deleting resource icon:', error);
        throw error;
    }
};