// api/locationApi.js
import axios from 'axios';

const API_BASE_URL = 'https://api.ridgetechcorp.com/api/locations';
const IMAGE_API_BASE_URL = 'https://api.ridgetechcorp.com/api';

// Get the list of locations
export const getLocationList = async () =>
{
    try
    {
        const response = await axios.get(API_BASE_URL);
        return response.data;
    } catch (error)
    {
        console.error('Error fetching location list:', error);
        throw error;
    }
};

// Create a new location
export const createLocation = async (locationData) =>
{
    try
    {
        const response = await axios.post(API_BASE_URL, locationData);
        return response.data;
    } catch (error)
    {
        console.error('Error creating location:', error);
        throw error;
    }
};

// Update an existing location
export const updateLocation = async (id, updatedData) =>
{
    try
    {
        const response = await axios.put(`${ API_BASE_URL }/${ id }`, updatedData);
        return response.data;
    } catch (error)
    {
        console.error('Error updating location:', error);
        throw error;
    }
};

// Delete a location
export const deleteLocation = async (id) =>
{
    try
    {
        const response = await axios.delete(`${ API_BASE_URL }/${ id }`);
        return response.data;
    } catch (error)
    {
        console.error('Error deleting location:', error);
        throw error;
    }
};

// Toggle location status (active/inactive)
export const toggleLocationStatus = async (id) =>
{
    try
    {
        const response = await axios.patch(`${ API_BASE_URL }/${ id }/toggle-status`);
        return response.data;
    } catch (error)
    {
        console.error('Error toggling location status:', error);
        throw error;
    }
};

