import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Input, Upload, message, Switch } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import
{
    createParentResource,
    deleteParentResource,
    deleteParentResourceIcon,
    getParentResourceList,
    updateParentResource,
    uploadParentResourceIcon
} from '../../api/parentResApi';

const ParentResource = () =>
{
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentResource, setCurrentResource] = useState({ name: '', logo: '' });
    const [logoFile, setLogoFile] = useState(null);

    // Fetch resource list on component mount
    useEffect(() =>
    {
        fetchResources();
    }, []);

    const fetchResources = async () =>
    {
        setLoading(true);
        try
        {
            const data = await getParentResourceList();
            setResources(data);
        } catch (error)
        {
            message.error('Failed to fetch resources.');
        } finally
        {
            setLoading(false);
        }
    };

    const handleOpenModal = (resource = { name: '', logo: '' }) =>
    {
        setCurrentResource(resource);
        if (resource._id)
        {
            setIsEditing(resource._id);
        }
        setIsModalOpen(true);
    };
    console.log(isEditing)
    const handleCloseModal = () =>
    {
        setIsModalOpen(false);
        setLogoFile(null);
        setCurrentResource({ name: '', logo: '' });
    };

    const handleLogoUpload = ({ file }) =>
    {
        setLogoFile(file);
    };

    const handleSaveResource = async () =>
    {
        setLoading(true);
        try
        {
            // Upload logo first if there's a new file selected
            let logoUrl = currentResource.logo;
            if (logoFile)
            {
                const formData = new FormData();
                formData.append('image', logoFile);
                const uploadResponse = await uploadParentResourceIcon(formData);
                logoUrl = uploadResponse.imageUrl; // Assumes backend returns { imageUrl: 'path/to/image' }
            }

            const resourceData = { ...currentResource, logo: logoUrl };
            console.log({ isEditing })
            if (isEditing)
            {
                await updateParentResource(currentResource._id, resourceData);
                message.success('Resource updated successfully');
            } else
            {
                await createParentResource(resourceData);
                message.success('Resource created successfully');
            }
            fetchResources();
            handleCloseModal();
        } catch (error)
        {
            message.error('Failed to save resource.');
        } finally
        {
            setLoading(false);
        }
    };

    const handleDeleteResource = async (id) =>
    {
        setLoading(true);
        try
        {
            await deleteParentResource(id);
            message.success('Resource deleted successfully');
            fetchResources();
        } catch (error)
        {
            message.error('Failed to delete resource.');
        } finally
        {
            setLoading(false);
        }
    };



    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (text) => <img src={text} alt="Logo" style={{ width: 50 }} />,
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <span>
                    <Button onClick={() => handleOpenModal(record)}>Edit</Button>
                    <Button onClick={() => handleDeleteResource(record._id)} danger>
                        Delete
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <div>
            <h2>Resource Management</h2>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleOpenModal()}
                style={{ marginBottom: '16px' }}
            >
                Add Resource
            </Button>
            <Table
                columns={columns}
                dataSource={resources}
                rowKey="_id"
                loading={loading}
            />

            <Modal
                title={isEditing ? 'Edit Resource' : 'Add Resource'}
                visible={isModalOpen}
                onOk={handleSaveResource}
                onCancel={handleCloseModal}
                okText="Save"
                cancelText="Cancel"
            >
                <Input
                    placeholder="Resource Name"
                    value={currentResource.name}
                    onChange={(e) =>
                        setCurrentResource({ ...currentResource, name: e.target.value })
                    }
                    style={{ marginBottom: '16px' }}
                />

                <Upload
                    beforeUpload={() => false} // Prevent auto-uploading
                    onChange={handleLogoUpload}
                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>Upload Logo</Button>
                </Upload>
                {currentResource.logo && (
                    <img
                        src={currentResource.logo}
                        alt="Current Logo"
                        style={{ width: 50, marginTop: 16 }}
                    />
                )}
            </Modal>
        </div>
    );
};

export default ParentResource;
