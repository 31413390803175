// src/components/TechnologyManager.js

import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Input, Select, Modal, notification } from 'antd';
import axios from 'axios';
import { getResourceList } from '../../../api/resourceApi';

const { Option } = Select;

const TechnologyManager = () =>
{
    const [technologies, setTechnologies] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [technologyName, setTechnologyName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editTechnology, setEditTechnology] = useState(null);

    // Fetch technologies and resources from backend
    useEffect(() =>
    {
        const loadTechnologies = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/technology');
                setTechnologies(response.data);
            } catch (error)
            {
                notification.error({
                    message: 'Error loading technologies',
                    description: 'There was an error fetching the technologies.',
                });
            }
        };

        const loadResources = async () =>
        {
            try
            {
                const data = await getResourceList();
                setResources(data);
            } catch (error)
            {
                notification.error({
                    message: 'Error loading resources',
                    description: 'There was an error fetching the resources.',
                });
            }
        };

        loadResources();
        loadTechnologies();
    }, []);

    // Handle form submission for creating or updating technology
    const handleSubmit = async () =>
    {
        if (!technologyName || options.length === 0)
        {
            notification.error({
                message: 'Error',
                description: 'Please provide a technology name and at least one option.',
            });
            return;
        }

        setLoading(true);

        try
        {
            let response;
            if (editTechnology)
            {
                // Update the technology
                response = await axios.put(`https://api.ridgetechcorp.com/technology/${ editTechnology._id }`, {
                    name: technologyName,
                    options,
                });
                notification.success({
                    message: 'Technology Updated',
                    description: `Technology ${ response.data.name } has been updated successfully.`,
                });
                setTechnologies(
                    technologies.map((tech) =>
                        tech._id === editTechnology._id ? response.data : tech
                    )
                );
            } else
            {
                // Create a new technology
                response = await axios.post('https://api.ridgetechcorp.com/technology/add', {
                    name: technologyName,
                    options,
                });
                notification.success({
                    message: 'Technology Created',
                    description: `Technology ${ response.data.name } has been created successfully.`,
                });
                setTechnologies([...technologies, response.data]);
            }

            // Reset the form
            setTechnologyName('');
            setOptions([]);
            setIsModalVisible(false);
            setEditTechnology(null);
        } catch (error)
        {
            notification.error({
                message: 'Error',
                description: 'There was an error submitting the technology.',
            });
        } finally
        {
            setLoading(false);
        }
    };

    // Handle delete action
    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/technology/${ id }`);
            notification.success({ message: 'Technology deleted successfully' });
            setTechnologies(technologies.filter((tech) => tech._id !== id));
        } catch (error)
        {
            notification.error({ message: 'Error deleting technology' });
        }
    };

    // Handle options change (selecting multiple options)
    const handleOptionsChange = (value) =>
    {
        setOptions(value);
    };

    // Handle editing of technology
    const handleEdit = (technology) =>
    {
        setTechnologyName(technology.name);
        setOptions(technology.options);
        setEditTechnology(technology);
        setIsModalVisible(true);
    };

    // Columns for Technology List Table
    const columns = [
        { title: 'Technology Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Options', dataIndex: 'options', key: 'options', render: (optionIds) =>
            {
                // Find the names of the resources based on the option IDs
                return optionIds
                    .map(optionId =>
                    {
                        console.log({ resources, optionId })
                        const resource = resources.find(r => r._id === optionId._id);
                        return resource ? resource.name : null; // Return resource name or null if not found
                    })
                    .filter(name => name) // Remove any null values
                    .join(', '); // Join the names with a comma
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        Edit
                    </Button>
                    <Button type="link" onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            {/* Button to open modal for creating technology */}
            <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 20 }}>
                Add New Technology
            </Button>

            {/* Technology List Table */}
            <Table rowKey="_id" dataSource={technologies} columns={columns} pagination={false} bordered />

            {/* Edit/Add Technology Modal */}
            <Modal
                title={editTechnology ? 'Edit Technology' : 'Add Technology'}
                visible={isModalVisible}
                onCancel={() =>
                {
                    setIsModalVisible(false);
                    setEditTechnology(null); // Reset edit
                    setTechnologyName('');
                    setOptions([]);
                }}
                onOk={handleSubmit}
                confirmLoading={loading}
            >
                <Form layout="vertical">
                    <Form.Item label="Technology Name" required>
                        <Input
                            value={technologyName}
                            onChange={(e) => setTechnologyName(e.target.value)}
                            placeholder="Enter technology name"
                        />
                    </Form.Item>

                    <Form.Item label="Technology Options" required>
                        <Select
                            mode="multiple"
                            value={options}
                            onChange={handleOptionsChange}
                            placeholder="Select options"
                        >
                            {resources && resources.map((resource) => (
                                <Option key={resource._id} value={resource._id}>
                                    {resource.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default TechnologyManager;
