
import React, { useState } from 'react';
import { Form, Input, Button, Avatar, message } from 'antd';
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { doc, getDoc } from 'firebase/firestore';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ db, setIsAuthenticated }) =>
{
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate()
    const onFinish = async (values) =>
    {
        setLoading(true);
        try
        {
            const { username, password } = values;
            const response = await axios.post('https://api.ridgetechcorp.com/api/user/login', { email: username, password })

            if (response)
            {

                // message.success('Login successful');
                localStorage.setItem('userdata', JSON.stringify(response.data))
                sessionStorage.setItem('isAuthenticated', 'true');
                setIsAuthenticated(true);
                navigate('/dashboard')
            } else
            {
                message.error('Invalid username or password');
            }

        } catch (error)
        {
            message.error('Login failed. Please check your username and password.');
        } finally
        {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <Form
                name="login_form"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <div className="avatar-container">
                    <Avatar size={64} icon={<UserOutlined />} />
                </div>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder="Password"
                        iconRender={visible => (visible ? <EyeTwoTone onClick={() => setPasswordVisible(false)} /> : <EyeInvisibleOutlined onClick={() => setPasswordVisible(true)} />)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
