import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDPbKWBkOHodYLemY23OSHZ6OCxhrXHBEI",
  authDomain: "rtcadmin-e25f8.firebaseapp.com",
  projectId: "rtcadmin-e25f8",
  storageBucket: "rtcadmin-e25f8.appspot.com",
  messagingSenderId: "407907255842",
  appId: "1:407907255842:web:a5645d88af6656a19e6c7b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
