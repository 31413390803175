import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import axios from 'axios';

const EditJob = ({ job, onSuccess, onClose }) =>
{
    const [form] = Form.useForm();

    useEffect(() =>
    {
        if (job)
        {
            form.setFieldsValue(job);
        }
    }, [job]);

    const onFinish = async (values) =>
    {
        // Split keyResponsibilities and qualifications into arrays
        console.log(values.keyResponsibility)
        values.keyResponsibility = !Array.isArray(values.keyResponsibility) ? values.keyResponsibility?.split('\n').map(item => item.trim()).filter(item => item) : values.keyResponsibility; // Split by newline, trim, and filter out empty lines
        values.qualification = !Array.isArray(values.qualification) ? values.qualification?.split('\n').map(item => item.trim()).filter(item => item) : values.qualification; // Split by newline, trim, and filter out empty lines

        // New field: Split emails into an array
        values.emailNotifications = values.emailNotifications.split(',').map(item => item.trim()).filter(item => item); // Split by comma, trim, and filter out empty lines

        await axios.put(`https://api.ridgetechcorp.com/api/jobs/${ job._id }`, values); // Adjust the API endpoint as needed
        form.resetFields();
        onSuccess();
    };

    if (!job) return null;

    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="title" label="Job Title" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="experience" label="Experience" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="opening" label="Openings" rules={[{ required: true }]}>
                <Input type="number" />
            </Form.Item>
            <Form.Item name="jobOverview" label="Job Overview" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="keyResponsibility" label="Key Responsibilities" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="qualification" label="Qualifications" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="emailNotifications" label="Notification Emails" rules={[{ required: true }]}>
                <Input placeholder="Enter emails separated by commas" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Update Job</Button>
            </Form.Item>
        </Form>
    );
};

export default EditJob;
