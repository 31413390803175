import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const FAQManagement = () =>
{
    const [faqs, setFaqs] = useState([]);
    const [pages, setPages] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingFAQ, setEditingFAQ] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedType, setSelectedType] = useState('');
    const [form] = Form.useForm();

    useEffect(() =>
    {
        fetchPagesAndResources();
        fetchFAQs();
    }, []);

    // Fetch Pages and Resources
    const fetchPagesAndResources = async () =>
    {
        try
        {
            const [pagesResponse, resourcesResponse] = await Promise.all([
                axios.get('https://api.ridgetechcorp.com/api/page'),
                axios.get('https://api.ridgetechcorp.com/api/resources'),
            ]);
            setPages(pagesResponse.data);
            setResources(resourcesResponse.data);
        } catch (error)
        {
            message.error('Failed to fetch pages and resources');
        }
    };

    // Fetch FAQs
    const fetchFAQs = async () =>
    {
        setLoading(true);
        try
        {
            let response;
            if (selectedEntity && selectedType)
            {
                response = await axios.get(
                    `https://api.ridgetechcorp.com/api/faqs/${ selectedType }/${ selectedEntity }`
                );
            } else
            {
                response = await axios.get('https://api.ridgetechcorp.com/api/faqs/getAllFAQs');
            }
            setFaqs(response.data);
        } catch (error)
        {
            message.error('Failed to fetch FAQs');
        } finally
        {
            setLoading(false);
        }
    };

    // Handle entity change
    const handleEntityChange = (value, type) =>
    {
        setSelectedEntity(value);
        setSelectedType(value.icon ? 'Page' : 'Resource');
        fetchFAQs();
    };

    // Show modal for adding or editing
    const showModal = (faq = null) =>
    {
        setEditingFAQ(faq);
        setIsModalVisible(true);
        if (faq)
        {
            form.setFieldsValue({
                questions: faq.questions || [],
            });
        } else
        {
            form.resetFields();
        }
    };

    // Handle form submission
    const handleFormSubmit = async () =>
    {
        try
        {
            const values = await form.validateFields();
            const payload = {
                ...values,
                pageId: selectedEntity,
                pageType: selectedType,
            };
            if (editingFAQ)
            {
                await axios.put(`https://api.ridgetechcorp.com/api/faqs/${ editingFAQ._id }`, payload);
                message.success('FAQ updated successfully');
            } else
            {
                await axios.post('https://api.ridgetechcorp.com/api/faqs/', payload);
                message.success('FAQ added successfully');
            }
            setIsModalVisible(false);
            fetchFAQs();
        } catch (error)
        {
            message.error('Failed to save FAQ');
        }
    };

    // Handle delete
    const handleDelete = async (faqId) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/api/faqs/${ faqId }`);
            message.success('FAQ deleted successfully');
            fetchFAQs();
        } catch (error)
        {
            message.error('Failed to delete FAQ');
        }
    };

    const columns = [
        {
            title: 'Questions',
            key: 'questions',
            render: (_, record) => (
                <ul>
                    {record.questions.map((q, index) => (
                        <li key={index}>
                            <strong>Q:</strong> {q.question} <br />
                            <strong>A:</strong> {q.answer}
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            title: 'Page Name',
            key: 'pageName',
            render: (_, record) => record.pageId?.name || 'N/A',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => showModal(record)}>
                        Edit
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Select
                placeholder="Select Page or Resource"
                style={{ width: 300, marginBottom: 16 }}
                onChange={(value) => handleEntityChange(value.value, value)}
                labelInValue
            >

                {pages.map((page) => (
                    <Option key={page._id} value={page._id} type="Page">
                        {page.name}
                    </Option>
                ))}

                {resources.map((resource) => (
                    <Option key={resource._id} value={resource._id} type="Resource">
                        {resource.name}
                    </Option>
                ))}
            </Select>
            <Button
                type="primary"
                onClick={() => showModal()}
                style={{ marginBottom: 16 }}
                disabled={!selectedEntity}
            >
                Add FAQ
            </Button>
            <Table
                columns={columns}
                dataSource={faqs}
                rowKey="_id"
                loading={loading}
                pagination={false}
            />
            <Modal
                title={editingFAQ ? 'Edit FAQ' : 'Add FAQ'}
                visible={isModalVisible}
                onOk={handleFormSubmit}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={form} layout="vertical">
                    <Form.List name="questions">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <div key={key} style={{ marginBottom: 16 }}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'question']}
                                            fieldKey={[fieldKey, 'question']}
                                            label="Question"
                                            rules={[{ required: true, message: 'Please input the question!' }]}
                                        >
                                            <Input placeholder="Enter question" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'answer']}
                                            fieldKey={[fieldKey, 'answer']}
                                            label="Answer"
                                            rules={[{ required: true, message: 'Please input the answer!' }]}
                                        >
                                            <Input.TextArea placeholder="Enter answer" rows={2} />
                                        </Form.Item>
                                        <Button type="link" danger onClick={() => remove(name)}>
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Question
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    );
};

export default FAQManagement;
