import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message, Table, Input, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import axios from 'axios';

const ImageGallery = ({ onSelectImage }) =>
{
    const [images, setImages] = useState([]);
    const [visible, setVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [customFilename, setCustomFilename] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch all images
    useEffect(() =>
    {
        const fetchImages = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/api/images');
                setImages(response.data);
            } catch (error)
            {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);

    // Upload new image with custom filename
    const handleUpload = async ({ file, onSuccess, onError }) =>
    {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('filename', customFilename); // Adding custom filename to formData

        setLoading(true);
        try
        {
            const response = await axios.post('https://api.ridgetechcorp.com/api/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setImages([...images, response.data]);
            message.success('Image uploaded successfully');
            onSuccess('OK'); // Mark upload as successful
        } catch (error)
        {
            console.error('Error uploading image:', error);
            message.error('Error uploading image');
            onError(error); // Mark upload as failed
        } finally
        {
            setLoading(false);
        }
    };

    // Delete image
    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/api/image/${ id }`);
            setImages(images.filter((image) => image._id !== id));
            message.success('Image deleted successfully');
        } catch (error)
        {
            message.error('Error deleting image');
        }
    };

    // Preview image
    const handlePreview = (imageUrl) =>
    {
        setPreviewImage(imageUrl);
        setPreviewVisible(true);
    };

    // Copy link to clipboard
    const handleCopyLink = (imageUrl) =>
    {
        navigator.clipboard.writeText(imageUrl)
            .then(() =>
            {
                message.success('Image URL copied to clipboard');
            })
            .catch((error) =>
            {
                message.error('Failed to copy URL');
                console.error('Error copying to clipboard:', error);
            });
    };

    // Define columns for the Ant Design Table
    const columns = [
        {
            title: 'Image',
            dataIndex: 'url',
            key: 'url',
            render: (text) => <img src={text} alt="image" style={{ width: '100px' }} />,
        },
        {
            title: 'Filename',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'Image URL',
            dataIndex: 'url',
            key: 'imageUrl',
            render: (url) => (
                <Tooltip title="Click to copy">
                    <Button
                        type="link"
                        icon={<CopyOutlined />}
                        onClick={() => handleCopyLink(url)}
                        style={{ padding: 0 }}
                    >
                        {url}
                    </Button>
                </Tooltip>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record) => (
                <div>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => handlePreview(record.url)}
                        style={{ marginRight: 8 }}
                    >
                        Preview
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record._id)}
                        danger
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <>
            <Button type="primary" onClick={() => setVisible(true)}>Open Image Gallery</Button>

            {/* Upload Modal */}
            <Modal
                title="Upload Image"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Input
                    placeholder="Enter custom filename"
                    value={customFilename}
                    onChange={(e) => setCustomFilename(e.target.value)}
                    style={{ marginBottom: 16 }}
                />
                <Upload
                    customRequest={handleUpload}
                    multiple={false}
                    listType="picture-card"
                    showUploadList={false}
                >
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                </Upload>
            </Modal>

            {/* Image Preview Modal */}
            <Modal
                visible={previewVisible}
                title="Image Preview"
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>

            {/* Image Table */}
            <Table
                columns={columns}
                dataSource={images}
                rowKey="_id"
                loading={loading}
            />
        </>
    );
};

export default ImageGallery;
