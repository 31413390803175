import React, { useEffect, useState } from 'react';
import { Table, Button, message, Popconfirm, Modal, Form, Input } from 'antd';
import axios from 'axios';

const TagList = () =>
{
    const [tags, setTags] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentTag, setCurrentTag] = useState(null);
    const [form] = Form.useForm();

    const userData = JSON.parse(localStorage.getItem('userdata')); // Retrieve userdata from localStorage

    useEffect(() =>
    {
        const fetchTags = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/api/tag');
                setTags(response.data);
            } catch (error)
            {
                console.error('Error fetching tags: ', error);
            }
        };

        fetchTags();
    }, []);

    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/api/tag/${ id }`);
            setTags(tags.filter((tag) => tag._id !== id));
            message.success('Tag deleted successfully');
        } catch (error)
        {
            console.error('Error deleting tag: ', error);
            message.error('Failed to delete the tag');
        }
    };

    const showEditModal = (tag) =>
    {
        setCurrentTag(tag);
        form.setFieldsValue(tag);
        setIsModalVisible(true);
    };

    const handleUpdate = async () =>
    {
        try
        {
            const updatedTag = { ...form.getFieldsValue() };
            await axios.put(`https://api.ridgetechcorp.com/api/tag/${ currentTag._id }`, updatedTag);
            message.success('Tag updated successfully');
            setIsModalVisible(false);
            setTags((prev) => prev.map((tag) => (tag._id === currentTag._id ? { ...tag, ...updatedTag } : tag)));
        } catch (error)
        {
            console.error('Error updating tag: ', error);
            message.error('Failed to update the tag');
        }
    };

    const handleAddTag = async () =>
    {
        try
        {
            const newTag = { ...form.getFieldsValue(), addedBy: userData.user._id }; // Add user information from localStorage
            const response = await axios.post('https://api.ridgetechcorp.com/api/tag', newTag);
            setTags([...tags, response.data]);
            message.success('Tag added successfully');
            setIsModalVisible(false);
            form.resetFields();
        } catch (error)
        {
            console.error('Error adding tag: ', error);
            message.error('Failed to add the tag');
        }
    };

    const columns = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
        },
        {
            title: 'Added By',
            dataIndex: ['addedBy', 'name'],
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => showEditModal(record)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure you want to delete this tag?"
                        onConfirm={() => handleDelete(record._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <Button
                type="primary"
                onClick={() =>
                {
                    form.resetFields();
                    setCurrentTag(null);
                    setIsModalVisible(true);
                }}
                style={{ marginBottom: '20px' }}
            >
                Add New Tag
            </Button>
            <Table columns={columns} dataSource={tags} rowKey="_id" />
            <Modal
                title={currentTag ? 'Edit Tag' : 'Add Tag'}
                visible={isModalVisible}
                onOk={currentTag ? handleUpdate : handleAddTag}
                onCancel={() => setIsModalVisible(false)}
                width={600}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Tag Name"
                        rules={[{ required: true, message: 'Please enter a tag name' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default TagList;
