import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Tooltip } from 'antd';
import axios from 'axios';
import { CopyOutlined } from '@ant-design/icons';

const JobList = ({ onEdit }) =>
{
    const [jobs, setJobs] = useState([]);

    const fetchJobs = async () =>
    {
        const response = await axios.get('https://api.ridgetechcorp.com/api/jobs'); // Adjust the API endpoint as needed
        setJobs(response.data);
    };

    const pauseJob = async (id) =>
    {
        await axios.put(`https://api.ridgetechcorp.com/api/jobs/${ id }/pause`);
        fetchJobs();
    };

    const resumeJob = async (id) =>
    {
        await axios.put(`https://api.ridgetechcorp.com/api/jobs/${ id }/resume`);
        fetchJobs();
    };

    const cloneJob = async (id) =>
    {
        await axios.post(`https://api.ridgetechcorp.com/api/jobs/clone/${ id }`);
        fetchJobs(); // Refresh the job list after cloning
    };

    useEffect(() =>
    {
        fetchJobs();
    }, []);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
        },
        {
            title: 'Openings',
            dataIndex: 'opening',
            key: 'opening',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <span className={text === 'paused' ? 'text-red-500' : 'text-green-500'}>{text}</span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={() => onEdit(record)}>Edit</Button>
                    {record.status === 'active' ? (
                        <Popconfirm
                            title="Are you sure to pause this job?"
                            onConfirm={() => pauseJob(record._id)}
                        >
                            <Button className="ml-2" type="default">Pause</Button>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure to resume this job?"
                            onConfirm={() => resumeJob(record._id)}
                        >
                            <Button className="ml-2" type="default">Resume</Button>
                        </Popconfirm>
                    )}

                    {/* Clone Button, visible only on hover */}
                    <Tooltip title="Clone Job">
                        <Button
                            icon={<CopyOutlined />}
                            className="ml-2"
                            onClick={() => cloneJob(record._id)}
                        >
                            Clone
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return <Table dataSource={jobs} columns={columns} rowKey="_id" />;
};

export default JobList;
