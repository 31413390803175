// pages/ViewSubmissions.js
import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import axios from 'axios';

const QueriesTable = () =>
{
    const [submissions, setSubmissions] = useState([]);

    useEffect(() =>
    {
        fetchSubmissions();
    }, []);

    const fetchSubmissions = async () =>
    {
        try
        {
            const response = await axios.get('https://api.ridgetechcorp.com/api/submission');
            setSubmissions(response.data.submission.reverse());
        } catch (error)
        {
            message.error('Failed to fetch submissions');
        }
    };

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'ISD Code', dataIndex: 'isd_code', key: 'isd_code' },
        { title: 'Phone Number', dataIndex: 'phone_number', key: 'phone_number' },
        { title: 'Service', dataIndex: 'choose_service', key: 'choose_service' },
        { title: 'Budget', dataIndex: 'select_budget', key: 'select_budget' },
        { title: 'Message', dataIndex: 'message', key: 'message' },
    ];

    return (
        <div>
            <h2>View Submissions</h2>
            <Table
                dataSource={submissions}
                columns={columns}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

export default QueriesTable;
