// pages/LocationManagement.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Switch, Modal, Input, Form } from 'antd';
import { getLocationList, createLocation, updateLocation, deleteLocation, toggleLocationStatus } from '../../../api/locationApi';

const LocationManagement = () =>
{
    const [locations, setLocations] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() =>
    {
        fetchLocations();
    }, []);

    const fetchLocations = async () =>
    {
        const data = await getLocationList();
        setLocations(data);
    };

    const handleAddLocation = async (values) =>
    {
        await createLocation(values);
        fetchLocations();
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleUpdateLocation = async (id, values) =>
    {
        await updateLocation(id, values);
        fetchLocations();
    };

    const handleDeleteLocation = async (id) =>
    {
        await deleteLocation(id);
        fetchLocations();
    };

    const handleToggleStatus = async (id) =>
    {
        await toggleLocationStatus(id);
        fetchLocations();
    };

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Location</Button>
            <Table dataSource={locations} rowKey="_id">
                <Table.Column title="Country" dataIndex="country" key="country" />
                <Table.Column title="Short Code" dataIndex="shortCode" key="shortCode" />
                <Table.Column title="Active" key="isActive" render={(text, record) => (
                    <Switch checked={record.isActive} onChange={() => handleToggleStatus(record._id)} />
                )} />
                <Table.Column title="Actions" key="actions" render={(text, record) => (
                    <>
                        <Button onClick={() => handleUpdateLocation(record._id, { /* update fields */ })}>Edit</Button>
                        <Button danger onClick={() => handleDeleteLocation(record._id)}>Delete</Button>
                    </>
                )} />
            </Table>
            <Modal title="Add Location" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={() => form.submit()}>
                <Form form={form} onFinish={handleAddLocation}>
                    <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="shortCode" label="Short Code">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default LocationManagement;
