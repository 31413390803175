// UserManagement.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Select } from 'antd';
import axios from 'axios';
const { Option } = Select
const UserManagement = () =>
{
    const [users, setUsers] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [form] = Form.useForm();

    useEffect(() =>
    {
        fetchUsers();
    }, []);

    const fetchUsers = async () =>
    {
        try
        {
            const response = await axios.get('https://api.ridgetechcorp.com/api/user'); // Adjust the endpoint according to your API
            setUsers(response.data);
        } catch (error)
        {
            message.error('Failed to fetch users');
        }
    };

    const handleAddUser = () =>
    {
        setEditingUser(null); // Reset the editing user
        setIsModalVisible(true);
    };

    const handleEditUser = (user) =>
    {
        form.setFieldsValue(user); // Set form values for editing
        setEditingUser(user);
        setIsModalVisible(true);
    };

    const handleDeleteUser = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/api/user/${ id }`); // Adjust the endpoint according to your API
            message.success('User deleted successfully');
            fetchUsers(); // Refresh user list
        } catch (error)
        {
            message.error('Failed to delete user');
        }
    };

    const handleModalOk = async () =>
    {
        const values = await form.validateFields();

        try
        {
            if (editingUser)
            {
                // Update user
                console.log({ editingUser })
                await axios.put(`https://api.ridgetechcorp.com/api/user/${ editingUser._id }`, values); // Adjust the endpoint according to your API
                message.success('User updated successfully');
            } else
            {
                // Add new user
                await axios.post('https://api.ridgetechcorp.com/api/user/register', values); // Adjust the endpoint according to your API
                message.success('User added successfully');
            }

            setIsModalVisible(false);
            fetchUsers(); // Refresh user list
            form.resetFields(); // Reset form fields
        } catch (error)
        {
            message.error('Failed to save user');
        }
    };

    const handleModalCancel = () =>
    {
        setIsModalVisible(false);
        form.resetFields(); // Reset form fields when modal is closed
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'access',
            key: 'role',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <span>
                    <Button onClick={() => handleEditUser(record)}>Edit</Button>
                    <Button danger onClick={() => handleDeleteUser(record._id)}>Delete</Button>
                </span>
            ),
        },
    ];
    console.log({ users })
    return (
        <div>
            <h2>User Management</h2>
            <Button type="primary" onClick={handleAddUser}>
                Add User
            </Button>
            <Table dataSource={users} columns={columns} rowKey="id" style={{ marginTop: 20 }} />

            <Modal
                title={editingUser ? 'Edit User' : 'Add User'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Save"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please input the user name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                    >
                        <Input />
                    </Form.Item>
                    {
                        !editingUser ?
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Please input the user password!' }]}
                            >
                                <Input.Password />
                            </Form.Item> : ''
                    }

                    <Form.Item
                        name="avatar"
                        label="Avatar URL"
                    >
                        <Input placeholder="Enter avatar URL" />
                    </Form.Item>
                    <Form.Item
                        name="access"
                        label="Access Level"
                        rules={[{ required: true, message: 'Please select the access level!' }]}
                    >
                        <Select placeholder="Select access level">
                            <Option value="admin">Admin</Option>
                            <Option value="editor">Editor</Option>
                            <Option value="viewer">Viewer</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="aboutUs"
                        label="About Us"
                    >
                        <Input.TextArea placeholder="Enter about us information" rows={4} />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'Please select the status!' }]}
                    >
                        <Select placeholder="Select status">
                            <Option value="active">Active</Option>
                            <Option value="inactive">Inactive</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default UserManagement;
