import React from 'react'

const ProfileDetails = () =>
{
  return (
    <div>
      <h3>Hi, Sachin </h3>
    </div>
  )
}

export default ProfileDetails
